import React from "react";
import { BackBy } from "../../components/BackBy/BackBy";
import { TokenAudit } from "../../components/TokenAudit/TokenAudit";

import { FuelThePower } from "./FuleThePower";
import { Info } from "./Info";
import { TokenDetail } from "./TokenDetail";

import "./FuelToken.css";

export const FuelToken = () => {
  return (
    <main id="site-main" className="token-main site-main">
      <FuelThePower />

      <BackBy />

      <TokenAudit />

      <TokenDetail />

      <Info />
    </main>
  );
};
