import React from "react";
import styled from "styled-components";
import { Container } from "../../components/Grid";

const ScMain = styled.div`
  background: black;
  padding: 4rem 0;
`;

const ScSection = styled.div`
  @media screen and (min-width: 992px) {
    display: flex;
    margin-left: -24px;
    margin-right: -24px;
  }
`;

const ScItem = styled.div`
  margin-bottom: 2rem;

  img {
    width: 100%;
  }

  @media screen and (min-width: 992px) {
    flex: 1;
    margin-left: 24px;
    margin-right: 24px;
  }
`;

const ScTitle = styled.h2`
  text-align: center;
  margin-bottom: 1.5em;
`;

const ScItemTitle = styled.h4``;

export const TokenDetail = () => {
  return (
    <ScMain>
      <Container>
        <ScTitle>Token Details</ScTitle>

        <ScSection>
          <ScItem>
            <img src="/images/token-details@2x.png" />
          </ScItem>

          <ScItem>
            <div className="flex mb4">
              <div className="flex-fixed h2 w2 br-100 bg-primary7 mt1 mr4"></div>
              <div className="">
                <ScItemTitle>Token Launch - 1%</ScItemTitle>
                <div className="onBackedCard mb3">Sept 2020</div>
                <div className="onBackedCard">4,000,000</div>
              </div>
            </div>
            <div className="flex mb4">
              <div className="flex-fixed h2 w2 br-100 bg-primary5 mt1 mr4"></div>
              <div className="">
                <ScItemTitle>Presale - 9%</ScItemTitle>
                <div className="onBackedCard mb3">3m - 24m</div>
                <div className="onBackedCard">30,000,000</div>
              </div>
            </div>
            <div className="flex mb4">
              <div className="flex-fixed h2 w2 br-100 bg-primary3 mt1 mr4"></div>
              <div className="">
                <ScItemTitle>Future Rounds - 30%</ScItemTitle>
                <div className="onBackedCard mb3">
                  21m linear unlock after 3m wait
                </div>
                <div className="onBackedCard">120,000,000</div>
              </div>
            </div>
          </ScItem>

          <ScItem>
            <div className="flex mb4">
              <div className="flex-fixed h2 w2 br-100 bg-gray2 mt1 mr4"></div>
              <div className="">
                <ScItemTitle>
                  Liquidity &amp; Attention Mining - 15%
                </ScItemTitle>
                <div className="onBackedCard mb3">48m diminishing emission</div>
                <div className="onBackedCard">60,000,000</div>
              </div>
            </div>
            <div className="flex mb4">
              <div className="flex-fixed h2 w2 br-100 bg-gray5 mt1 mr4"></div>
              <div className="">
                <ScItemTitle>Staking Rewards - 15%</ScItemTitle>
                <div className="onBackedCard mb3">48m diminishing emission</div>
                <div className="onBackedCard">60,000,000</div>
              </div>
            </div>
            <div className="flex mb4">
              <div className="flex-fixed h2 w2 br-100 bg-gray7 mt1 mr4"></div>
              <div className="">
                <ScItemTitle>Team - 20%</ScItemTitle>
                <div className="onBackedCard mb3">
                  42m linear unlock after 6m wait
                </div>
                <div className="onBackedCard">80,000,000</div>
              </div>
            </div>
            <div className="flex mb4">
              <div className="flex-fixed h2 w2 br-100 bg-gray7 mt1 mr4"></div>
              <div className="">
                <ScItemTitle>Partnerships - 10%</ScItemTitle>
                <div className="onBackedCard mb3">
                  21m linear unlock after 3m wait
                </div>
                <div className="onBackedCard">40,000,000</div>
              </div>
            </div>
          </ScItem>
        </ScSection>
      </Container>
    </ScMain>
  );
};
