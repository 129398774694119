import React from "react";
import "./BackBy.css";

export const BackBy = () => {
  return (
    <div className="bb-main">
      <div className="container">
        <div className="bb-list">
          <div className="bb-item">
            <img className="bb-item--img" src="/images/darius@2x.png" />
            <h4 className="bb-item--name">Darius Sit</h4>
            <p className="bb-item--title">QCP Capital, Founder</p>
            <img className="bb-item--logo" src="/images/qcp@2x.png" />
          </div>
          <div className="bb-item">
            <img className="bb-item--img" src="/images/kainwarwick@2x.png" />
            <h4 className="bb-item--name">Kain Warwick</h4>
            <p className="bb-item--title">Synthetix, Founder</p>
            <img className="bb-item--logo" src="/images/synthetix@2x.png" />
          </div>
          <div className="bb-item">
            <img className="bb-item--img" src="/images/loiluu@2x.png" />
            <h4 className="bb-item--name">Loi Luu</h4>
            <p className="bb-item--title">Kyber Network, Co-Founder</p>
            <img className="bb-item--logo" src="/images/kyber@2x.png" />
          </div>
          <div className="bb-item">
            <img className="bb-item--img" src="/images/bobbyong@2x.png" />
            <h4 className="bb-item--name">Bobby Ong</h4>
            <p className="bb-item--title">CoinGecko, Co-Founder</p>
            <img className="bb-item--logo" src="/images/coingecko@2x.png" />
          </div>

          <div className="bb-item">
            <img className="bb-item--img" src="/images/mattdibb@2x.png" />
            <h4 className="bb-item--name">Matt Dibb</h4>
            <p className="bb-item--title">Astronaut Capital, Founder</p>
            <img
              className="bb-item--logo"
              src="/images/astronautcapital@2x.png"
            />
          </div>

          <div className="bb-item">
            <img className="bb-item--img" src="/images/han@2x.png" />
            <h4 className="bb-item--name">Han Kao</h4>
            <p className="bb-item--title">Strategic Round Capital, Principal</p>
            <img className="bb-item--logo" src="/images/src@2x.png" />
          </div>
        </div>
      </div>
    </div>
  );
};
