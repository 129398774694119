import React from "react";

import styled from "styled-components";
import { Container } from "../Grid";

const ScMain = styled.div`
  padding: 6rem 0;
  background: black;
  color: white;
`;

const ScTitle = styled.h2`
  text-align: center;
  margin-bottom: 1em;
`;

const ScPanel = styled.div`
  @media screen and (min-width: 992px) {
    display: flex;
    margin-left: -1rem;
    margin-right: -1rem;
  }
`;

const ScItem = styled.div`
  flex: 1;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
`;

const ScCard = styled.div`
  padding: 2rem;
  border-radius: 4px;
`;

export const TokenAudit = () => {
  return (
    <ScMain>
      <Container>
        <ScTitle>Token Audits</ScTitle>
        <ScPanel>
          <ScItem>
            <ScCard className="bg-backedCard">
              <img src="/images/quantstamp@2x.png" width="200" />
              <div className="flex mt2">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://certificate.quantstamp.com/full/power-trade"
                  className="onBackedCard mb0 mr4"
                >
                  Full Report
                </a>
              </div>
            </ScCard>
          </ScItem>
          <ScItem>
            <ScCard className="bg-backedCard">
              <img src="/images/halborn@2x.png" height="37" />
              <div className="flex mt2">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://github.com/HalbornSecurity/PublicReports/blob/master/Solidity%20Smart%20Contract%20Audits/PowerTrade_Fuel_smartcontract_halborn_report_V1.pdf"
                  className="onBackedCard mb0 mr4"
                >
                  Token Contract
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://github.com/HalbornSecurity/PublicReports/blob/master/Solidity%20Smart%20Contract%20Audits/PowerTrade_vesting_smartcontract_halborn_report_V1.pdf"
                  className="onBackedCard mb0 "
                >
                  Vesting Contract
                </a>
              </div>
            </ScCard>
          </ScItem>
        </ScPanel>
      </Container>
    </ScMain>
  );
};
