import React from "react";
import styled from "styled-components";
import { Container } from "../../components/Grid";

const ScMain = styled.div`
  padding: 4rem 0;
  background: black;
`;

const ScTokenInfo = styled.div`
  @media screen and (min-width: 992px) {
    display: flex;
    font-size: 16px;
  }
`;

const ScTokenItem = styled.div`
  flex: 2;
  margin-bottom: 1rem;
`;

const ScTitle = styled.h4`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0.25em;
`;

export const Info = () => {
  return (
    <ScMain>
      <Container>
        <ScTokenInfo>
          <ScTokenItem>
            <ScTitle>PowerTrade Fuel</ScTitle>
            <div className="onBackedCard">Name</div>
          </ScTokenItem>
          <ScTokenItem style={{ flex: 1 }}>
            <ScTitle>PTF</ScTitle>
            <div className="onBackedCard">Symbol</div>
          </ScTokenItem>
          <ScTokenItem>
            <ScTitle>40,509,017</ScTitle>
            <div className="onBackedCard">Circulating Supply</div>
          </ScTokenItem>
          <ScTokenItem>
            <ScTitle>400,000,000</ScTitle>
            <div className="onBackedCard">Total Supply</div>
          </ScTokenItem>
          <ScTokenItem>
            <ScTitle>
              <a
                href="https://etherscan.io/address/0xC57d533c50bC22247d49a368880fb49a1caA39F7"
                className="white hover-white no-underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                0xC57d5...aA39F7
              </a>
            </ScTitle>
            <div className="onBackedCard">Token Address</div>
          </ScTokenItem>
        </ScTokenInfo>
      </Container>
    </ScMain>
  );
};
