import React from "react";
import styled from "styled-components";

import { Container } from "../../components/Grid";

const ScBanner = styled.div`
  padding: 2rem 0;
  background-color: black;
`;

const ScHeader = styled.h3`
  font-size: 48px;
`;
const ScContent = styled.p`
  font-size: 20px;
  line-height: 1.4;
  opacity: 0.8;
`;

const ScPanel = styled.div`
  @media screen and (min-width: 992px) {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }
`;

const ScPanelContent = styled.div`
  flex: 1;
`;
const ScPanelCover = styled.div`
  flex: 2;

  img {
    width: 100%;
  }

  @media screen and (min-width: 992px) {
    img {
      margin-left: 0;
    }
  }
`;

const ScDownloadBtn = styled.a`
  height: 64px;
  background: #bfff00;
  padding-left: 2rem;
  padding-right: 2rem;
  display: inline-block;
  border-radius: 64px;
  color: black;
  line-height: 64px;
  font-size: 16px;
  font-weight: bold;

  img {
    margin-right: 8px;
  }
`;

export const Banner = () => {
  return (
    <ScBanner>
      <Container>
        <ScPanel>
          <ScPanelCover>
            <img src="/images/fuel-token-wings@1x.png" />
          </ScPanelCover>
          <ScPanelContent>
            <ScHeader>Fuel (PTF)</ScHeader>
            <ScContent>
              The PowerTrade Fuel Token (PTF) is a DAO token which governs the
              treasury that covers PowerTrade traders in the unlikely event of a
              black swan.
            </ScContent>
            <ScDownloadBtn
              href="/assets/docs/token-paper.pdf"
              download="PowerTrade Fuel Token Paper"
            >
              <img src="/images/icons/download.svg" />
              Get the Token Paper
            </ScDownloadBtn>
          </ScPanelContent>
        </ScPanel>
      </Container>
    </ScBanner>
  );
};
