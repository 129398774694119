import styled from "styled-components";
import { UniversalButton } from "./universal-button";

export const ScRoot = styled.div`
  margin-top: 6rem;
  margin-bottom: 6rem;

  @media screen and (min-width: 992px) {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
`;

export const ScSubTitle = styled.h6`
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  color: #acff00;
  margin-top: 1em;
  margin-bottom: 1rem;
  letter-spacing: 1.5px;
`;

export const ScTitle = styled.h2`
  letter-spacing: -1px;
  color: #ffffff;
  font-weight: 600;
  font-size: 32px;
  line-height: 1;
  margin-top: 16px;
  margin-bottom: 16px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: 992px) {
    font-size: 48px;
    line-height: 1;
  }
`;

export const ScHelper = styled.p`
  max-width: 620px;
  font-weight: 400;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
  line-height: 1.4;
  color: #a1a2a6;
  margin-bottom: 24px;

  b {
    color: white;
    font-weight: normal;
  }
`;

export const ScWhiteButton = styled(UniversalButton)`
  display: inline-block;
  background: white;
  border-radius: 2px;
  text-align: center;
  font-size: 14px;
  color: #0b0b0f;
  font-weight: 600;
  transition: all 0.3s;
  min-width: 120px;
  height: 40px;
  line-height: 40px;
  border-radius: 20px;
  padding-left: 24px;
  padding-right: 24px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    color: #0b0b0f;
    opacity: 0.75;
    text-decoration: none;
  }

  svg {
    margin-left: 6px;
  }
`;

export const ScGrayButton = styled(ScWhiteButton)`
  background: #161619;
  color: white;
  border: 1px solid transparent;
  transition: all 0.3s;

  &:hover {
    color: white;
    border-color: white;
    text-decoration: none;
    opacity: 1;
  }

  svg {
    margin-left: 8px;
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.75;
  }
`;

export const ScItem = styled.div`
  max-width: 320px;
  margin-bottom: 24px;
  padding-right: 12px;

  svg {
    margin-right: 6px;
    width: 16px;
    height: 16px;
    vertical-align: middle;
  }
  em {
    margin-right: 6px;
    color: white;
  }
  span {
    color: #868f97;
  }

  @media screen and (min-width: 992px) {
    margin-bottom: 64px;
  }
`;

export const ScHorizontalDiv = styled.div`
  max-width: 300px;
  margin-top: 46px;
  margin-bottom: 46px;
  height: 1px;
  margin-left: auto;
  margin-right: auto;
  background: rgb(254, 254, 254);
  background: linear-gradient(
    270deg,
    rgba(254, 254, 254, 0) 0%,
    rgba(255, 255, 255, 0.25) 50%,
    rgba(255, 255, 255, 0) 100%
  );

  @media screen and (min-width: 992px) {
    width: 600px;
    max-width: 600px;
    margin-top: 80px;
    margin-bottom: 100px;
  }
`;

export const ScVerticalDiv = styled.div`
  height: 80px;
  width: 1px;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(180, 240, 2, 1) 100%
  );
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: 992px) {
    margin-bottom: 24px;
  }
`;
