import * as React from "react";
import type { HeadFC } from "gatsby";

import { FuelToken } from "../containers/fuel-token/FuelToken";
import { Banner } from "../containers/fuel-token/Banner";
import { Layout } from "../components/Layout";

const InstitutionalPage = () => {
  return (
    <Layout>
      <Banner />
      <FuelToken />
    </Layout>
  );
};

export default InstitutionalPage;

export const Head: HeadFC = () => <title>PowerTrade Token Fuel</title>;
