import React from "react";
import styled from "styled-components";

import { Container } from "../../components/Grid";
import { ScHelper, ScTitle as ScTitleSrc } from "../../components/Shared";

const ScMain = styled.div`
  margin-top: 4rem;
  margin-bottom: 4rem;

  @media screen and (min-width: 992px) {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
`;

const ScTitle = styled(ScTitleSrc)`
  text-align: center;
`;

const ScHeader = styled.div`
  margin-bottom: 2rem;

  @media screen and (min-width: 992px) {
    margin-bottom: 4rem;
  }
`;

const ScList = styled.div`
  @media screen and (min-width: 992px) {
    display: flex;
  }
`;

const ScItem = styled.div`
  flex: 1;

  @media screen and (min-width: 992px) {
    padding-right: 24px;
  }
`;

export const FuelThePower = () => {
  return (
    <ScMain>
      <Container>
        <ScHeader>
          <ScTitle>Fuel the Power</ScTitle>
          <ScHelper>
            This DAO is an alternative to exchange insurance funds that are
            generally funded via liquidation and trade fees and often outperform
            the market during downturns.
          </ScHelper>
        </ScHeader>

        <ScList>
          <ScItem>
            <div>
              <img src="/images/icons/stake.svg" />
            </div>
            <h3 className="mt4">Staking</h3>
            <p>
              Token holders can also benefit with PowerTrade by staking their
              token to receive reduced trading fees and staking rewards.
            </p>
          </ScItem>
          <ScItem>
            <div>
              <img src="/images/icons/governance.svg" />
            </div>
            <h3 className="mt4">Governance</h3>
            <p>
              Participate in the governance of the DAO treasury and decide on
              cover events by voting as a DAO.
            </p>
          </ScItem>
          <ScItem>
            <div>
              <img src="/images/icons/rewards.svg" />
            </div>
            <h3 className="mt4">Rewards</h3>
            <p>
              Earn tokens from community distribution programs that reward early
              users and participants in the PowerTrade ecosystem.
            </p>
          </ScItem>
        </ScList>
      </Container>
    </ScMain>
  );
};
